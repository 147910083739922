export default {
  namespaced: true,
  actions: {

    async getRules(_, { Authtoken, companyID }) {
      try {
        const API_URL = `${this.state.base_URL}transportation-pricing-rules`;

        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Authtoken}`,
          'User-Company': companyID,
        };

        const res = await fetch(API_URL, {
          method: 'GET',
          headers,
        });

        const response = await res.json();

        if ('errors' in response) {
          return response.errors;
        }

        return response;
      } catch (error) {
        console.error('Error fetching rules:', error);
        throw new Error(error);
      }
    },

    async deleteRule(_, { Authtoken, ruleID }) {
      try {
        const API_URL = `${this.state.base_URL}transportation-pricing-rules/${ruleID}`;

        const headers = {
          Accept: "application/json",
          Authorization: `Bearer ${Authtoken}`,
        };

        const res = await fetch(API_URL, {
          method: "DELETE",
          headers,
        });

        const response = await res.json();

        if (response.error) {
          console.error("Error deleting rule:", response.error);
          throw new Error(response.error);
        }

        return response;
      } catch (error) {
        console.error("Error deleting rule:", error);
        throw new Error(error);
      }
    },

    async createRule(_, { Authtoken, data }) {
      try {
        const API_URL = `${this.state.base_URL}transportation-pricing-rules`;

        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Authtoken}`
        };

        const res = await fetch(API_URL, {
          method: 'POST',
          headers,
          body: JSON.stringify(data),
        });

        const response = await res.json();

        if ('errors' in response) {
          console.error('Error creating rule:', response.errors);
          throw new Error(response.errors);
        }

        return response;
      } catch (error) {
        console.error('Error creating rule:', error);
        throw new Error(error);
      }
    },

  },
};
